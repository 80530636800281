
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import { defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { setLocale } from "yup";
import { pt } from "yup-locale-pt";
import { useField, useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { Auth, Hub, API, graphqlOperation } from "aws-amplify";
import { listProfileFeatures } from "@/graphql/queries";
import { updateProfileFeature } from "@/graphql/mutations";
import { createEnterpriseFeature } from "@/core/database/data/enterprise/enterprise-graphql-mutations-operations.js";
import { createProfileFeature } from "@/core/database/data/profile/profile-graphql-mutations-operations.js";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

setLocale(pt);

interface CurrentAuthenticatedUser {
  username: string;
}

interface Address {
  cep: string;
  street: string;
  number: string;
  city: string;
  state: string;
  country: string;
}

interface AccountVerification {
  phone_number: string;
  email: string;
}

interface CurrentProfile {
  id: string;
  username: string;
  avatar: string;
  name: string;
  birthday: string;
  age: number;
  gender: string;
  verified: boolean;
  geolocation: null;
  metadata: string;
  enabled: boolean;
  auth_signup_id: string;
}

interface KTWizard {
  sector: number;
  companyName: string;
  bio: string;
  yourName: string;
  password: string;
  signupPhone: string;
  confirmSignupPhone: number;
  leadRelationship: number;
  onlyOnlineBussiness: boolean;
  cep: string;
  addressLine: string;
  addressNumber: string;
  city: string;
  country: string;
  instagram: string;
  facebook: string;
  youtube: string;
  tiktok: string;
  twitter: string;
  whatsapp: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCVVNumber: string;
}

export default defineComponent({
  name: "Seja Membro",
  components: {
    HeaderPage,
  },
  setup() {
    const title = "Inovação, gestão e mercado";
    const subtitle =
      "<p>É um momento desafiador, exige criatividade, resiliência, muito empenho e união.</p>" +
      "<p>Cadastre seu negócio e faça parte de uma comunidade vibrante e empreendedora.</p>" +
      "<p>Aproveite as oportunidades de networking, eventos exclusivos, recursos de suporte ao empreendedorismo e amplie sua visibilidade no cenário local.</p>" +
      "<p>Este é o lugar para conectar-se com empreendedores, descobrir tendências e impulsionar o crescimento do seu negócio.</p>" +
      "<p>Junte-se a nós e faça parte dessa rede de inovação e sucesso.</p>";
    const search = "";

    const router = useRouter();

    const stepperRef = ref<HTMLElement | null>(null);

    // Create a form validation schema
    const wizardSchema = {
      sector: Yup.string()
        .required()
        .label("Informe em qual segmento o seu negócio atua. Este campo "),
      leadRelationship: Yup.string()
        .required()
        .label(
          "Informe em qual seu relacionamento com este negócio. Este campo "
        ),
      companyName: Yup.string()
        .required()
        .label("O nome da empresa deve ser preenchido. Este campo "),
      yourName: Yup.string()
        .required()
        .label("Nos diga o seu nome. Este campo "),
      password: Yup.string()
        .required()
        .label("Proteja sua conta com uma senha de acesso. Este campo "),
      bio: Yup.string()
        .required()
        .label("Nos conte um pouco sobre o seu negócio. Este campo "),
      email: Yup.string()
        .required()
        .email()
        .label("Informe o email da empresa. Este campo "),
      website: Yup.string()
        .nullable()
        .label(
          "Informe o site da empresa. Exemplo: www.seunegocio.com.br. Este campo "
        ),
      phone: Yup.string().matches(
        /([0-9]{5})([\\-])?([0-9]{4})/,
        "Exemplo de telefone válido: 9XXXX-XXXX"
      ),
      whatsapp: Yup.string()
        .required()
        .label("Informe o número de whatsapp. Este campo ")
        .matches(
          /([0-9]{5})([\\-])?([0-9]{4})/,
          "Exemplo de telefone válido: 9XXXX-XXXX"
        ),
      signupPhone: Yup.string()
        .required()
        .label("Informe o número de whatsapp. Este campo ")
        .matches(
          /([0-9]{5})([\\-])?([0-9]{4})/,
          "Exemplo de telefone válido: 9XXXX-XXXX"
        ),
    };

    // Create a form context with the validation schema
    useForm({
      validationSchema: wizardSchema,
    });

    const sectors = ref([
      {
        id: 1,
        label: "Alimentação e Bebidas",
      },
      {
        id: 2,
        label: "Agronegócio",
      },
      {
        id: 3,
        label: "Automotivo",
      },
      {
        id: 4,
        label: "Construção",
      },
      {
        id: 5,
        label: "Conteúdo digital",
      },
      {
        id: 6,
        label: "Economia Verde",
      },
      {
        id: 7,
        label: "Educação",
      },
      {
        id: 8,
        label: "Entretenimento",
      },
      {
        id: 9,
        label: "Informática",
      },
      {
        id: 10,
        label: "Máquinas e Equipamentos",
      },
      {
        id: 11,
        label: "Mercado Imobiliário",
      },
      {
        id: 12,
        label: "Pesquisa e Desenvolvimento",
      },
      {
        id: 13,
        label: "Pets",
      },
      {
        id: 14,
        label: "Saúde & Bem-Estar",
      },
      {
        id: 15,
        label: "Serviços Especializados",
      },
      {
        id: 16,
        label: "Serviços Pessoais",
      },
      {
        id: 17,
        label: "Serviços Financeiros",
      },
      {
        id: 18,
        label: "Vestuário e Calçados",
      },
      {
        id: 19,
        label: "Vendas e marketing",
      },
      {
        id: 20,
        label: "Tecnologia da Informação e Comunicação",
      },
      {
        id: 21,
        label: "Outros",
      },
    ]);

    const leadRelationshipList = ref([
      {
        id: 1,
        label: "Proprietário",
      },
      {
        id: 2,
        label: "Funcionário",
      },
      {
        id: 3,
        label: "Consultor",
      },
      {
        id: 4,
        label: "Parceiro de Negócios",
      },
      {
        id: 5,
        label: "Investidor",
      },
      {
        id: 6,
        label: "Fornecedor",
      },
      {
        id: 7,
        label: "Interessado/Estudante/Estagiário",
      },
      {
        id: 8,
        label: "Cliente",
      },
      {
        id: 9,
        label: "Mídia/Imprensa",
      },
      {
        id: 10,
        label: "Não tenho relação direta",
      },
    ]);

    //form data
    const data = ref<KTWizard>({
      sector: 0,
      companyName: "",
      bio: "",
      yourName: "",
      password: "",
      signupPhone: "",
      confirmSignupPhone: 0,
      leadRelationship: 0,
      onlyOnlineBussiness: false,
      cep: "",
      addressLine: "",
      addressNumber: "",
      city: "",
      country: "Brasil",
      instagram: "",
      facebook: "",
      youtube: "",
      tiktok: "",
      twitter: "",
      whatsapp: "",
      name: "",
      email: "",
      phone: "",
      website: "",
      nameOnCard: "",
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCVVNumber: "",
    });

    const currentAuthenticatedUser = ref<CurrentAuthenticatedUser>({
      username: "",
    });

    const address = ref<Address>({
      cep: "",
      street: "",
      number: "",
      city: "",
      state: "",
      country: "",
    });

    const accountVerification = ref<AccountVerification>({
      phone_number: "",
      email: "",
    });

    const currentProfile = ref<CurrentProfile>({
      id: "",
      username: "",
      avatar: "",
      name: "",
      birthday: "",
      age: 0,
      gender: "",
      verified: true,
      geolocation: null,
      metadata: "",
      enabled: true,
      auth_signup_id: "",
    });

    const {
      value: sector,
      errorMessage: sectorError,
      validate: validateSector,
    } = useField("sector");
    const {
      value: leadRelationship,
      errorMessage: leadRelationshipError,
      validate: validateLeadRelationship,
    } = useField("leadRelationship");
    const {
      value: companyName,
      errorMessage: companyNameError,
      validate: validateCompanyName,
    } = useField("companyName");
    const {
      value: yourName,
      errorMessage: yourNameError,
      validate: validateYourName,
    } = useField("yourName");
    const {
      value: password,
      errorMessage: passwordError,
      validate: validatePassword,
    } = useField("password");
    const {
      value: signupPhone,
      errorMessage: signupPhoneError,
      validate: validateSignupPhone,
    } = useField("signupPhone");
    const {
      value: confirmSignupPhone,
      errorMessage: confirmSignupPhoneError,
      validate: validateConfirmSignupPhone,
    } = useField("confirmSignupPhone");
    const {
      value: bio,
      errorMessage: bioError,
      validate: validateBio,
    } = useField("bio");
    const {
      value: email,
      errorMessage: emailError,
      validate: validateEmail,
    } = useField("email");
    const {
      value: website,
      errorMessage: websiteError,
      validate: validateWebsite,
    } = useField("website");
    const {
      value: phone,
      errorMessage: phoneError,
      validate: validatePhone,
    } = useField("phone");
    const {
      value: whatsapp,
      errorMessage: whatsappError,
      validate: validateWhatsapp,
    } = useField("whatsapp");

    const _stepperObj = ref<StepperComponent | null>(null);

    onMounted(() => {
      // global.window.analytics.page("Signup Business Member");
      if (!stepperRef.value) {
        return;
      }

      _stepperObj.value = StepperComponent.createInstance(stepperRef.value);
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Parceiros", "Faça Parte");
    });

    // Auth.currentAuthenticatedUser().then((user) => {
    //   currentAuthenticatedUser.value = user;

    //   if (currentAuthenticatedUser.value.username) {
    //     await API.graphql(
    //       graphqlOperation(listProfileFeatures, {
    //         filter: {
    //           auth_signup_id: {
    //             eq: currentAuthenticatedUser.value.username,
    //           },
    //           enabled: {
    //             eq: true,
    //           },
    //         },
    //       })
    //     ).then((response) => {
    //       // Se já existir perfil, ok
    //       if (response.data.listProfileFeatures.items.length > 0) {
    //         currentProfile.value = response.data.listProfileFeatures.items[0];
    //         if (currentProfile.value) {
    //           let metadata = JSON.parse(currentProfile.value.metadata);
    //           currentProfile.value.metadata = metadata;
    //           address.value = metadata.address
    //             ? metadata.address
    //             : address.value;
    //           accountVerification.value = {
    //             phone_number: metadata.phone_number,
    //             email: metadata.email,
    //           };
    //         }
    //       } else {
    //         // Caso contrário, builda com os dados do user logged
    //         currentProfile.value.username = slugify(
    //           user.attributes.given_name,
    //           "."
    //         ).toLowerCase();
    //         currentProfile.value.name = user.attributes.given_name;
    //         currentProfile.value.auth_signup_id = user.username;
    //         accountVerification.value = {
    //           phone_number: user.attributes.phone_number,
    //           email: user.attributes.email,
    //         };
    //       }
    //     });
    //   }
    // });

    // console.log(currentProfile.value);
    const nextStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      if (_stepperObj.value.getCurrentStepIndex() === 1) {
        data.value.sector = sector.value as number;
        data.value.companyName = companyName.value as string;
        data.value.bio = bio.value as string;

        validateSector().then((result) => {
          validateCompanyName().then((result1) => {
            validateBio().then((result3) => {
              if (result.valid && result1.valid && result3.valid) {
                if (!_stepperObj.value) {
                  return;
                }

                _stepperObj.value.goNext();
              } else {
                Swal.fire({
                  text: "Queremos entender melhor como podemos ajudar a impulsionar o seu negócio. Por favor, revise e complete suas informações!",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, vou revisar!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
            });
          });
        });
        return;
      }

      if (_stepperObj.value.getCurrentStepIndex() === 3) {
        data.value.email = email.value as string;
        data.value.website = ("https://" + website.value) as string;
        data.value.phone = ("+55" + phone.value) as string;
        data.value.whatsapp = ("+55" + whatsapp.value) as string;

        validateEmail().then((result) => {
          validatePhone().then((result1) => {
            validateWebsite().then((result2) => {
              validateWhatsapp().then((result3) => {
                if (
                  result.valid &&
                  result1.valid &&
                  result2.valid &&
                  result3.valid
                ) {
                  if (!_stepperObj.value) {
                    return;
                  }
                  _stepperObj.value.goNext();
                } else {
                  Swal.fire({
                    text: "Indique os meios pelos quais os clientes e parceiros podem entrar em contato com sua empresa. Por favor, complete suas informações!",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, vou revisar!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                }
              });
            });
          });
        });
        return;
      }

      if (_stepperObj.value.getCurrentStepIndex() === 5) {
        _stepperObj.value.goNext();
      }

      if (_stepperObj.value.getCurrentStepIndex() === 6) {
        data.value.yourName = yourName.value as string;
        data.value.password = password.value as string;
        data.value.signupPhone = ("+55" + signupPhone.value) as string;
        data.value.leadRelationship = leadRelationship.value as number;

        validateYourName().then((result) => {
          validatePassword().then((result1) => {
            validateSignupPhone().then((result2) => {
              validateLeadRelationship().then((result3) => {
                if (
                  result.valid &&
                  result1.valid &&
                  result2.valid &&
                  result3.valid
                ) {
                  if (!_stepperObj.value) {
                    return;
                  }

                  try {
                    const username = slugify(
                      data.value.yourName,
                      "_"
                    ).toLowerCase();
                    const password = data.value.password;
                    const phone_number = data.value.signupPhone;
                    const slugnameBusiness = slugify(
                      data.value.companyName,
                      "."
                    ).toLowerCase();

                    var normalizeData = {
                      name: data.value.companyName,
                      url: data.value.website,
                      slugname: slugnameBusiness,
                      metadata: JSON.stringify({
                        bio: data.value.bio,
                        sector: JSON.stringify(
                          sectors.value.filter((x) => x.id == data.value.sector)
                        ),
                        address: {
                          onlyOnlineBussiness: data.value.onlyOnlineBussiness,
                          cep: data.value.cep,
                          addressLine: data.value.addressLine,
                          addressNumber: data.value.addressNumber,
                          city: data.value.city,
                          country: data.value.country,
                        },
                        social_network: {
                          instagram: data.value.instagram,
                          facebook: data.value.facebook,
                          youtube: data.value.youtube,
                          tiktok: data.value.tiktok,
                          twitter: data.value.twitter,
                        },
                        channels: {
                          phone: data.value.phone,
                          whatsapp: data.value.whatsapp,
                          email: data.value.email,
                          website: data.value.website,
                        },
                        owner: {
                          username: username,
                          displayName: data.value.yourName,
                          signupPhone: data.value.signupPhone,
                          leadRelationship: JSON.stringify(
                            leadRelationshipList.value.filter(
                              (x) => x.id == data.value.leadRelationship
                            )
                          ),
                        },
                      }),
                      enabled: true,
                      verified: false,
                    };

                    // Create Business
                    createEnterpriseFeature(normalizeData).then(
                      (enterpriseId) => {
                        // Create User Cognito
                        Auth.signUp({
                          username: phone_number,
                          password,
                          attributes: {
                            given_name: data.value.yourName,
                            phone_number: phone_number, // optional - E.164 number convention
                          },
                          autoSignIn: {
                            enabled: true, // optional - enables auto sign in after user is confirmed
                          },
                        })
                          .then((response) => {
                            if (!currentProfile.value.id) {
                              createProfileFeature({
                                username: username,
                                avatar: null,
                                name: data.value.yourName,
                                birthday: null,
                                age: 0,
                                gender: null,
                                geolocation: null,
                                verified: false,
                                metadata: JSON.stringify({
                                  auth_signup_id: response.userSub,
                                  phone_number,
                                  business_collection: [
                                    {
                                      business_id: enterpriseId,
                                      business_slugname: slugnameBusiness,
                                      business_whatsapp: data.value.whatsapp,
                                      business_website: data.value.website,
                                      leadRelationship: JSON.stringify(
                                        leadRelationshipList.value.filter(
                                          (x) =>
                                            x.id == data.value.leadRelationship
                                        )
                                      ),
                                    },
                                  ],
                                  address: {
                                    cep: null,
                                    street: null,
                                    number: null,
                                    city: null,
                                    state: null,
                                    country: null,
                                  },
                                }),
                                auth_signup_id: response.userSub,
                                enabled: true,
                              });
                            }
                          })
                          .catch((err) => {
                            if (
                              err ==
                              "UsernameExistsException: An account with the given phone_number already exists."
                            ) {
                              Swal.fire({
                                text: "Ops! Já possuímos um cadastro com este número de telefone. Por favor, faça login com sua conta existente.",
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: "Entrar",
                                customClass: {
                                  confirmButton: "btn fw-bold btn-light-danger",
                                },
                              });
                              router.push("/login");
                            }
                          });
                      }
                    );

                    // Create Profile Feature
                    // const profileId = createProfileFeature(normalizeData);

                    // Track Event
                    // global.window.window.analytics.track({
                    //   anonymousId: uuidv4(),
                    //   event: "Account Created",
                    //   properties: {
                    //     account_name: data.value.companyName,
                    //     slugname_business: slugnameBusiness,
                    //     email_business: data.value.email,
                    //     whatsapp_business: data.value.whatsapp,
                    //     linked_id_business: enterpriseId,
                    //     user_signup_phone: phone_number,
                    //     user_profile_name: data.value.yourName,
                    //     username_profile: username,
                    //     business_name: data.value.companyName,
                    //   },
                    // });
                  } catch (error) {
                    console.log("error signing up:", error);
                  }

                  _stepperObj.value.goNext();
                } else {
                  Swal.fire({
                    text: "Essas informações são importantes para garantir a melhor experiência em nossa comunidade. Por favor, revise e complete seus dados!",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, vou revisar!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                }
              });
            });
          });
        });
      }

      _stepperObj.value.goNext();
    };

    const prevStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      data.value.confirmSignupPhone = confirmSignupPhone.value as number;

      validateConfirmSignupPhone().then((result) => {
        if (result.valid) {
          if (!_stepperObj.value) {
            return;
          }

          try {
            Auth.confirmSignUp(
              data.value.signupPhone,
              data.value.confirmSignupPhone.toString()
            );
            router.push("/home");
          } catch (error) {
            console.log("error confirming sign up", error);
          }

          _stepperObj.value.goNext();
        }
      });

      return;
    };

    return {
      currentAuthenticatedUser,
      currentProfile,
      title,
      subtitle,
      search,
      sectors,
      sector,
      sectorError,
      companyName,
      companyNameError,
      yourName,
      yourNameError,
      signupPhone,
      signupPhoneError,
      confirmSignupPhone,
      confirmSignupPhoneError,
      password,
      passwordError,
      bio,
      bioError,
      email,
      emailError,
      website,
      websiteError,
      phone,
      phoneError,
      whatsapp,
      whatsappError,
      data,
      formSubmit,
      nextStep,
      prevStep,
      stepperRef,
      leadRelationshipList,
      leadRelationship,
      leadRelationshipError,
    };
  },
  mounted() {
    // this.loadProfile();
  },
  methods: {
    // async loadProfile() {
    //   await Auth.currentAuthenticatedUser().then((user) => {
    //     this.currentAuthenticatedUser = user;
    //   });

    //   if (this.currentAuthenticatedUser.username) {
    //     const response: any = await API.graphql(
    //       graphqlOperation(listProfileFeatures, {
    //         filter: {
    //           auth_signup_id: {
    //             eq: this.currentAuthenticatedUser.username,
    //           },
    //           enabled: {
    //             eq: true,
    //           },
    //         },
    //       })
    //     );
    //     if (response.data.listProfileFeatures.items.length > 0) {
    //       currentProfile = response.data.listProfileFeatures.items[0];
    //       if (currentProfile) {
    //         let metadata = JSON.parse(currentProfile.metadata);
    //         currentProfile.metadata = metadata;
    //         this.address = metadata.address ? metadata.address : this.address;
    //         this.accountVerification = {
    //           phone_number: metadata.phone_number,
    //           email: metadata.email,
    //         };
    //       }
    //     } else {
    //       Auth.currentAuthenticatedUser().then((user) => {
    //         currentProfile.username = slugify(
    //           user.attributes.given_name,
    //           "."
    //         ).toLowerCase();
    //         currentProfile.name = user.attributes.given_name;
    //         currentProfile.auth_signup_id = user.username;
    //         this.accountVerification = {
    //           phone_number: user.attributes.phone_number,
    //           email: user.attributes.email,
    //         };
    //       });
    //     }
    //     console.log(currentProfile);
    //   }
    // },
    async resendConfirmationCode(signupPhone) {
      try {
        await Auth.resendSignUp(signupPhone);
        console.log("code resent successfully");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    },
    listenToAutoSignInEvent() {
      Hub.listen("auth", ({ payload }) => {
        const { event } = payload;
        if (event === "autoSignIn") {
          const user = payload.data;
          console.log(user);
          // assign user
        } else if (event === "autoSignIn_failure") {
          // redirect to sign in page
          console.log("user auto signin failure");
        }
      });
    },
  },
});
